<template>
    <ui-card v-if="transaction" title="Transaction details">
        <ui-list>
            <ui-list-item title="Transaction ID:">
                <template #text>
                    <ui-link :to="{ name: 'transactions-info', query: { id: transaction.id } }">
                        {{ transaction.transaction_id }}
                    </ui-link>
                </template>
            </ui-list-item>
            <ui-list-item title="amount:">
                <template #text>
                    <div class="fw-bold">
                        <transaction-amount :type="transaction.type" :amount="this.transaction.amount + this.transaction.fee" :currency="transaction.currency" />
                    </div>
                </template>
            </ui-list-item>
            <ui-list-item title="Folio:">
                <template #text>
                    {{ transaction.transfer.folio }}
                </template>
            </ui-list-item>
            <ui-list-item title="Reference ID:" :text="transaction.transfer.numeric_reference" />
            <ui-list-item v-if="transaction.transfer.concept && transaction.transfer.concept !== ''" title="Concept:" :text="transaction.transfer.concept" />
            <ui-list-item title="Status:">
                <template #text>
                    <ui-status :status="transaction.status" />
                </template>
            </ui-list-item>
            <ui-list-item title="Created at:">
                <template #text>
                    {{ $dayjs(transaction.transfer.created_at).format('L') }} <br />
                    {{ $dayjs(transaction.transfer.created_at).format('LTS') }}
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import TransactionAmount from '@/components/ui/TransactionAmount.vue';
import UiLink from '@/components/ui/Link';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
        UiLink,
        TransactionAmount,
    },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },
};
</script>
