<template>
    <ui-card v-if="transaction" title="Chargeback details">
        <ui-list>
            <ui-list-item title="Chargeback:">
                <template #text>
                    <div class="fw-bold">
                        <transaction-amount :type="transaction.type" :amount="transaction.amount" :currency="transaction.currency" />
                    </div>
                </template>
            </ui-list-item>
            <ui-list-item title="Reason:">
                <template #text>
                    <template v-if="transaction.type === 'charge'">
                        {{ transaction.chargeback.reason }}
                    </template>
                    <template v-else>
                        {{ transaction.payer.email }}
                    </template>
                </template>
            </ui-list-item>
            <ui-list-item title="Transaction ID:">
                <template #text>
                    <ui-link :to="{ name: 'transactions-info', query: { id: transaction.chargeback.transaction.id } }">
                        {{ transaction.chargeback.transaction.transaction_id }}
                    </ui-link>
                </template>
            </ui-list-item>
            <ui-list-item v-if="transaction.payout.concept && transaction.payout.concept !== ''" title="Concept:" :text="transaction.payout.concept" />
            <ui-list-item title="Status:">
                <template #text>
                    <ui-status :status="transaction.chargeback.status" />
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import TransactionAmount from '@/components/ui/TransactionAmount.vue';
import UiLink from '@/components/ui/Link';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
        UiLink,
        TransactionAmount,
    },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },

    methods: {
        getTransfer(name = '', lastname = '', type) {
            const transferred = 'Transferred to';
            const received = 'Received from';
            let message = `${transferred} ${name || ''} ${lastname || ''}`;
            if (type === 'payment') {
                message = `${received} ${name || ''} ${lastname || ''}`;
                return message;
            }
            return message;
        },
    },
};
</script>
