<template>
    <ui-card>
        <div v-if="transaction && transaction.refunds" class="row align-items-center">
            <div class="col-12 col-md-8 lead text-capitalize mb-2">Refund</div>

            <div class="col-12 lead mb-4">
                <div class="border rounded p-3 text-center">
                    Total amount refunded:
                    <p class="text-red fw-bold m-0">{{ _currency(transaction.refunds.total_amount, transaction.currency) }}</p>
                </div>
            </div>

            <div v-for="(refund, index) in transaction.refunds.data" :key="refund.id">
                <div class="col-12">
                    <p class="m-0 fw-bold">
                        ID:
                        <span class="font-light">{{ refund.gateway_refund_id }}</span>
                    </p>
                </div>
                <div class="col-12">
                    <p class="m-0 fw-bold">
                        Amount:
                        <span class="font-light">{{ _currency(refund.amount, transaction.currency) }}</span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="m-0 fw-bold">
                        Reason:
                        <span class="font-light">{{ refund.reason }}</span>
                    </p>
                </div>
                <div class="col-12">
                    <p class="text-capitalize m-0 fw-bold">
                        Type:
                        <span class="font-light">{{ refund.type }}</span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="text-capitalize m-0 fw-bold">
                        Destination:
                        <span class="font-light">{{ refund.destination }}</span>
                    </p>
                </div>
                <div class="col-12">
                    <p class="text-capitalize m-0 fw-bold">
                        Date:
                        <span class="font-light">{{ $dayjs(refund.created_at).format('LLLL') }}</span>
                    </p>
                </div>
                <div class="col-12">
                    <p class="text-capitalize m-0 fw-bold">
                        Status:
                        <ui-status class="me-auto" :status="refund.status" />
                    </p>
                </div>
                <div v-if="refund.error" class="col-12">
                    <p class="text-capitalize m-0 fw-bold">
                        Error:
                        {{ refund.error }}
                    </p>
                </div>
                <hr v-if="transaction.refunds.data.length > 1 && index !== transaction.refunds.data.length - 1" />
            </div>
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: { UiCard, UiStatus },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getRefund() {
            const refundedBy = 'Refunded by';
            const refundedTo = 'Refunded to';
            if (this.transaction.type === 'payment') {
                return refundedBy;
            }

            if (this.transaction.type === 'charge') {
                return refundedTo;
            }

            return '';
        },
    },
};
</script>
