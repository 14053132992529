<template>
    <div class="row">
        <a class="col-auto align-self-center" :href="file.link" target="_blank">
            <img v-if="!file_error" :src="file.link" :alt="file.id" class="img-thumbnail img-file" @error="file_error = true" />
            <i v-else class="far fa-file-pdf fa-3x" style="margin-right: 0.85rem"></i>
        </a>

        <a class="col-auto fs-14px text-decoration-none" :href="file.link" target="_blank">
            <span class="break-all">{{ file.id }}</span> <br />
            <small class="text-muted">{{ $dayjs(file.upload_at).format('LL') }}</small>
        </a>
        <i
            role="button"
            aria-label="Remove selected file"
            class="col-auto ms-auto fa-sm fas fa-trash-alt text-danger"
            @click="$emit('showModal', file.id)"
        ></i>
    </div>
</template>

<script>
export default {
    props: {
        file: { type: Object, default: () => {} },
    },

    data() {
        return {
            file_error: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.img-file {
    width: 50px;
    height: 50px;
    padding: 0;
    object-fit: cover;
}
</style>
