<template>
    <ui-card v-if="order && order.payments || charges" :title="getTitle">
        <ui-list v-if="charges">
            <ui-list-item v-if="charges.folio" title="folio" :text="charges.folio" />
            <ui-list-item v-if="charges.reference" title="reference" :text="charges.reference" />
            <ui-list-item v-if="charges.gateway" title="gateway:" :text="charges.gateway.toUpperCase()" />
            <ui-list-item v-if="charges.type" title="type" :text="charges.type" />
            <ui-list-item v-if="charges.status" title="status">
                <template #text>
                    <ui-status :status="charges.status"></ui-status>
                </template>
            </ui-list-item>
            <ui-list-item title="chargebacks">
                <template #text>
                    <ui-badge v-if="transaction.chargeback" variant="danger">
                        Has chargebacks
                    </ui-badge>
                    <ui-badge v-else variant="secondary">
                        No chargebacks
                    </ui-badge>
                </template>
            </ui-list-item>
            <template v-if="charges.method">
                <ui-list-item v-if="charges.method.last4" title="last 4 digits:" :text="charges.method.last4" />
                <ui-list-item v-if="charges.method.name" title="name" :text="charges.method.name" />
                <ui-list-item v-if="charges.method.type" title="type pay method" :text="charges.method.type" />
                <ui-list-item v-if="charges.method.brand" title="brand" :text="charges.method.brand" />
                <ui-list-item v-if="charges.method.bankname" title="bankname" :text="charges.method.bankname" />
                <ui-list-item v-if="charges.method.domestic" title="domestic" :text="charges.method.domestic == true ? 'Si': 'No'" />
            </template>
            <template v-if="charges.payment_method && charges.payment_method.type == 'oxxo'">
                <ui-list-item v-if="charges.payment_method.store_name" title="store" :text="charges.payment_method.store_name" />
                <ui-list-item v-if="charges.payment_method.service_name" title="service name" :text="charges.payment_method.service_name" />
                <ui-list-item v-if="charges.payment_method.reference" title="reference" :text="charges.payment_method.reference" />
                <ui-list-item v-if="charges.payment_method.barcode_url" title="barcode" :img="charges.payment_method.barcode_url" />
            </template>
            <template v-if="charges.payment_method && charges.payment_method.type == 'credit'">
                <ui-list-item v-if="charges.payment_method.account_type" title="account type" :text="charges.payment_method.account_type" />
                <ui-list-item v-if="charges.payment_method.name" title="name" :text="charges.payment_method.name" />
                <ui-list-item v-if="charges.payment_method.brand" title="brand name" :text="charges.payment_method.brand" />
                <ui-list-item v-if="charges.payment_method.last4" title="last4" :text="charges.payment_method.last4" />
                <ui-list-item v-if="charges.payment_method.type" title="type" :text="charges.payment_method.type" />
            </template>
        </ui-list>
        <ui-list v-else>
            <ui-list-item v-if="order.payments.gateway" title="gateway:" :text="order.payments.gateway.toUpperCase()" />
            <ui-list-item v-if="order.payments.data[0].payment_method && order.payments.data[0].payment_method.last4" title="last 4 digits:" :text="order.payments.data[0].payment_method.last4" />
            <ui-list-item title="chargebacks">
                <template #text>
                    <ui-badge v-if="transaction.chargeback" variant="danger">
                        Has chargebacks
                    </ui-badge>
                    <ui-badge v-else variant="secondary">
                        No chargebacks
                    </ui-badge>
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiBadge from '@/components/ui/Badge';
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: { UiBadge, UiCard, UiList, UiListItem, UiStatus },
    props: {
        order: {
            type: Object,
            default: null,
            requerired: false
        },
        transaction: {
            type: Object,
            default: null,
            requerired: true
        },
    },
    data() {
        return {
            charges: this.getFirstCharge(this.transaction.charges),
        }
    },
    computed: {
        getTitle() {
            const data = this.order.payments.data;
            let type = null;

            if (data && data.length > 0) {
                const payment = data[0];
                const methodTypes = payment.payment_method_types;

                if (methodTypes && methodTypes.length > 0) {
                    type = methodTypes[0];
                } else {
                    type = payment.payment_method?.type;
                }
            }

            const payment_type = type ? ` (${type})` : '';
            return `Payment method${payment_type}`;
        },
    },
    methods: {
        getFirstCharge(charges) {
            return Array.isArray(charges) && charges.length > 0 ? charges[0] : null;
        },
    },
};
</script>
