<template>
    <ui-card title="Customer Information">
        <ui-list>
            <ui-list-item title="Name:" :text="transfer.ordering_name" />
            <ui-list-item title="Account:" :text="transfer.ordering_account" />
            <ui-list-item title="Bank:" :text="transfer.ordering_bank" />
        </ui-list>
    </ui-card>
    <ui-card title="Beneficiary Information">
        <ui-list>
            <ui-list-item title="Name:" :text="transfer.beneficiary_name" />
            <ui-list-item title="CLABE:" :text="transfer.beneficiary_account" />
            <ui-list-item title="Bank:" :text="transfer.beneficiary_bank" />
            <ui-list-item title="Tax ID:" :text="transfer.beneficiary_tax_id" />
            <ui-list-item title="Company:" :text="transfer.company" />
        </ui-list>
    </ui-card>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiCard from '@/components/ui/Card';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        ContactLinks,
        UiBadge,
        UiCard,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        transfer: {
            type: Object,
            default: null,
        },
    },
};
</script>
