<template>
    <ui-card title="Add your notes">
        <div v-if="CAN([scope])">
            <ui-text-area id="note" name="note" label="Leave a note here" v-model:value="note" />
            <br>
            <ui-button variant="primary" :loading="is_loading" @click="sendNote" class="w-100">
                Save note
            </ui-button>
        </div>
        <div v-else-if="notes.length > 0" class="notes-container d-flex flex-column">
            <div class="list-group">
                <div v-for="i in notes" :key="i.id" class="fs-14px list-group-item">
                    <div class="d-flex w-100 justify-content-between">
                        <small class="text-muted">{{ $dayjs().to(i.created_at) }}</small>
                        <i
                            role="button"
                            aria-label="Remove selected note"
                            class="fa-sm fas fa-trash-alt text-danger"
                            @click="modalRemove(i.id)"
                        ></i>
                    </div>
                    <p class="mb-1">{{ i.text }}</p>
                </div>
            </div>
            <b-modal title="Remove selected note" ref="modal_remove_note">
                Are you sure you want to remove this note?
                <template #footer>
                    <b-button variant="light" @click="$refs.modal_remove_note.hide()">Close</b-button>
                    <b-button variant="outline-danger" :disabled="is_loading" @click="removeNote">
                        <custom-spinner v-if="is_loading" class="spinner-border-sm me-1"></custom-spinner> Remove</b-button
                    >
                </template>
            </b-modal>
        </div>
        <div v-else-if="!notes.length">
            <empty-state img="searching-data.svg" text="There are no notes for this transaction yet..." />
        </div>
    </ui-card>
</template>

<script>
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import UiButton from '@/components/ui/buttons/Button'
import UiCard from '@/components/ui/Card.vue';
import UiTextArea from '@/components/ui/TextArea.vue';
import EmptyState from '@/components/ui/EmptyState.vue';


export default {
    props: {
        notes: { type: Array, default: () => [] },
        scope: { type: String, default: null, required: true }
    },
    components: {
        CustomSpinner,
        UiButton,
        UiCard,
        UiTextArea,
        EmptyState,
    },
    data() {
        return {
            note: '',
            selected_note: null,
            is_loading: false,
            transaction_id: this.$route.query.id,
        };
    },
    methods: {
        async sendNote() {
            try {
                if (String(this.note).trim() === '') {
                    return;
                }

                this.is_loading = true;
                const { data } = await this.$axios.post(`/_/transactions/${this.transaction_id}/notes`, {
                    text: this.note,
                });
                this.$emit('update:notes', [ ...this.notes, data ])
                this.note = '';
            } catch (error) {
                console.error(error);
            } finally {
                this.is_loading = false;
            }
        },

        modalRemove(id) {
            this.selected_note = id;
            this.$refs.modal_remove_note.show();
        },

        async removeNote() {
            try {
                this.is_loading = true;
                await this.$axios.delete(`/_/transactions/${this.transaction_id}/notes/${this.selected_note}`);
                const notes = this.notes.filter(note => note.id !== this.selected_note);
                this.$emit('update:notes', notes);
                this.$refs.modal_remove_note.hide();
                this.selected_note = null;
            } catch (error) {
                console.error(error);
            } finally {
                this.is_loading = false;
            }
        },
    },
};
</script>

<style style="scss" scoped>
.custom-textarea {
    resize: none;
    font-size: 16px;
}

.notes-container {
    max-height: 20rem;
    overflow-y: auto;
}
</style>
