<template>
    <ui-card v-if="order && Object.entries(order).length" title="Customer Information">
        <ui-list>
            <ui-list-item title="Customer id:" :text="order.customer_id">
                <template #text>
                    <ui-link v-if="order.account_customer_id" weight="medium" :to="{ name: 'accounts-info', query: { id: order.account_customer_id } }" target="_blank">
                        {{ order.account_customer_id }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        The account is not registered.
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Name:" :text="`${order.first_name} ${order.last_name || ''}`" />
            <ui-list-item title="Email:">
                <template #text>
                    <ui-link
                        v-if="order.email"
                        weight="medium"
                        :link="`mailto:${order.email}`"
                    >
                        {{ order.email }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Phone:">
                <template #text>
                    <ui-link
                        v-if="order.phone"
                        weight="medium"
                        :link="`tel:+${order.phone}`"
                    >
                        {{ order.phone }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Contact:">
                <template #text>
                    <contact-links :phone="order.phone || null" :email="order.email || null" />
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiCard from '@/components/ui/Card';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        ContactLinks,
        UiBadge,
        UiCard,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        order: {
            type: Object,
            default: null,
        },
    },
};
</script>
