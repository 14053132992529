<template>
    <ui-card title="Your files">
        <div v-if="dataFiles" class="mt-3 list-group list-images">
            <div v-for="i in files_copy" :key="i.id" class="list-group-item">
                <files-item :file="i" :key="i.id" @showModal="showModalDelete"></files-item>
            </div>
            <b-modal title="Remove selected file" ref="modal_remove_file">
                Are you sure you want to remove this file?
                <template #footer>
                    <b-button variant="light" @click="$refs.modal_remove_file.hide()">Close</b-button>
                    <b-button variant="outline-danger" :disabled="is_loading" @click="removeFile">
                        <custom-spinner v-if="is_loading" class="spinner-border-sm me-1"></custom-spinner> Remove</b-button
                    >
                </template>
            </b-modal>
        </div>

        <p v-else class="text-center m-0">No files uploaded yet</p>
    </ui-card>
</template>

<script>
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import FilesItem from '@/components/files/FilesItem.vue';
import UiCard from '@/components/ui/Card';

export default {
    props: {
        dataFiles: { type: Array, default: null },
    },

    components: { CustomSpinner, FilesItem, UiCard },

    data() {
      return {
        selected_file: null,
        transaction_id: this.$route.query.id,
        is_loading: false,
        files_copy: [],
      }
    },

    watch: {
        dataFiles() {
            this.files_copy = this.dataFiles ? [...this.dataFiles] : [];
        },
    },

    methods: {
      showModalDelete(id) {
        this.selected_file = id;
        this.$refs.modal_remove_file.show();
      },

      async removeFile() {
          try {
            this.is_loading = true;
            await this.$axios.delete(`/_/transactions/${this.transaction_id}/files/${this.selected_file}`);
            this.files_copy = this.files_copy.filter(file => file.id !== this.selected_file);
            this.selected_file = null;
          } catch (error) {
            console.error(error)
          } finally {
            this.is_loading = false;
            this.$refs.modal_remove_file.hide();
          }
      }
    }
};
</script>

<style lang="scss" scoped>
.list-images {
    max-height: 22rem;
    overflow-y: auto;

    img {
        max-width: 50px;
    }
}
</style>
