<template>
    <ui-card v-if="transaction" class="account-information" title="Account information">
        <ui-list>
            <ui-list-item v-if="transaction.account_id" title="Account id:">
                <template #text>
                    <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: transaction.account_id } }" target="_blank">
                        {{ transaction.account_id }}
                    </ui-link>
                </template>
            </ui-list-item>
            <ui-list-item title="Name:" :text="get(transaction, 'account_id')">
                <template #text>
                    {{ get(transaction, 'account.first_name') }} {{ get(transaction, 'account.last_name') }}
                </template>
            </ui-list-item>
            <ui-list-item title="Email:">
                <template #text>
                    <ui-link
                        v-if="transaction.account && transaction.account.email"
                        weight="medium"
                        :link="`mailto:${transaction.account.email}`"
                    >
                        {{ transaction.account.email }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Phone:">
                <template #text>
                    <ui-link
                        v-if="transaction.account && transaction.account.phone"
                        weight="medium"
                        :link="`tel:+${transaction.account.phone}`"
                    >
                        {{ transaction.account.phone }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
            </ui-list-item>
            <ui-list-item title="Contact:">
                <template #text>
                    <contact-links
                        :phone="get(transaction, 'account.phone', null)"
                        :email="get(transaction, 'account.email', null)"
                    />
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import { get } from 'lodash';

import ContactLinks from '@/components/ui/ContactLinks';
import UiBadge from '@/components/ui/Badge';
import UiCard from '@/components/ui/Card.vue';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    components: {
        ContactLinks,
        UiBadge,
        UiCard,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        transaction: { type: Object, default: null },
    },
    methods: {
        get(data, path, def = 'Empty data') {
            return get(data, path, def);
        },
    }
}
</script>

<style lang="scss">
.account-information {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 0px;

        @media (max-width: 540px) {
            display: initial;
        }
        
        &:not(:last-child) {
            border-bottom: 1px solid $ecart-secondary-100;
        }
    }

    &__list-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        width: 150px;
        max-width: 100%;
    }
    
    &__list-text {
        font-weight: 500;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }
}
</style>