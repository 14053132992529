<template>
    <div class="transaction-info">
        <b-button class="mb-3 col-auto print" variant="light" @click="$router.go(-1)">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div class="d-flex align-items-center mb-4">
            <div v-if="transaction" class="transaction-info__number">
                Operation {{ transaction.transaction_id }}
            </div>
            <div class="ms-auto">
                <ui-button
                    class="print"
                    icon="fas fa-print"
                    variant="primary"
                    @click="print(`TR-${transaction.transaction_id}.jpeg`)"
                >
                    Print
                </ui-button>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12" :class="{ 'col-xl-7': transaction_type === 'order', 'col-lg-6': transaction_type !== 'order' }">
                <order-details v-if="transaction_type === 'order' && !transaction.chargeback || transaction_type === 'transfer' && Object.keys(transaction.order).length" :transaction="transaction" />
                <transaction-details 
                    v-if="transaction_type === 'transfer' && !Object.keys(transaction.order).length"
                    :paid_at="transaction.transfer.created_at"
                    :type="transaction.type"
                    :amount="transaction.amount"
                    :fee="transaction.fee"
                    :currency="transaction.currency"
                    activity="Tranfer"
                    :reference="transaction.transfer.numeric_reference"
                    :concept="transaction.transfer.concept"
                    :status="transaction.status"
                />
                <order-account-information v-if="transaction_type === 'order' || transaction_type === 'transfer' && Object.keys(transaction.order).length" :transaction="transaction" />
                <transaction-payout v-if="transaction_type === 'payout'" :transaction="transaction" />
                <transaction-transference v-if="transaction_type === 'transfer' && !Object.keys(transaction.order).length" :transaction="transaction" />
                <transaction-chargeback v-if="transaction_type === 'order' && transaction.chargeback" :transaction="transaction"/>
                <transactions-withdrawals v-if="transaction_type === 'withdrawal'" :transaction="transaction" />
                <order-refund v-if="transaction && transaction.refunds.data" :transaction="transaction" />
                <main-note class="print" v-model:notes="notes" :scope="'write_transactions'" />
            </div>
            <div :class="{ 'col-xl-5': transaction_type === 'order', 'col-lg-6': transaction_type !== 'order' }">
                <template v-if="transaction_type === 'order' || transaction_type === 'transfer' && Object.keys(transaction.order).length">
                    <order-customer-information v-if="!transaction.purchase && !transaction.chargeback" :order="transaction.order" />
                    <order-payment-method v-if="!transaction.chargeback" :transaction="transaction" :order="transaction.order" />
                </template>
                <template v-if="transaction_type === 'transfer' && !Object.keys(transaction.order).length">
                    <transfer-information :transfer="transaction.transfer" />
                </template>
                <ui-card title="Upload your files" class="print" v-if="CAN(['write_transactions'])">
                    <form-file
                        v-model="files"
                        :is-uploading="is_uploading"
                        :base64="true"
                        :accept="['.jpeg', '.jpg', '.png', '.webp', '.pdf']"
                        wrapper-class="mb-0"
                    >
                        <div class="col-lg-6 col-xl-4">
                            <button :disabled="!files.length || is_uploading" class="btn btn-sm btn-primary w-100 mt-3" @click="uploadFiles">
                                <custom-spinner v-if="is_uploading" class="spinner-border-sm me-1"></custom-spinner>
                                <i v-else class="fas fa-upload me-1"></i>Upload files
                            </button>
                        </div>
                    </form-file>
                </ui-card>
                <files-container class="print" :data-files="data_files" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import FilesContainer from '@/components/files/FilesContainer';
import FormFile from '@/components/forms/FormFile.vue';
import MainNote from '@/components/notes/MainNote';
import OrderAccountInformation from '@/components/transactions/OrderAccountInformation.vue';
import OrderDetails from '@/components/transactions/OrderDetails';
import OrderCustomerInformation from '@/components/transactions/OrderCustomerInformation';
import OrderPaymentMethod from '@/components/transactions/OrderPaymentMethod';
import TransferInformation from '@/components/transactions/TransferInformation.vue';
import TransactionDetails from '@/components/transactions/TransactionDetails';
import OrderRefund from '@/components/transactions/OrderRefund';
import TransactionPayout from '@/components/transactions/TransactionPayout';
import TransactionTransference from '@/components/transactions/TransactionTransfer';
import TransactionsWithdrawals from '@/components/transactions/Withdrawals';
import TransactionChargeback from '../../components/transactions/TransactionChargeback';
import UiCard from '@/components/ui/Card';
import UiButton from '@/components/ui/buttons/Button';

export default {
    components: {
        CustomSpinner,
        FilesContainer,
        FormFile,
        MainNote,
        OrderAccountInformation,
        OrderDetails,
        OrderCustomerInformation,
        OrderPaymentMethod,
        OrderRefund,
        TransactionPayout,
        TransactionsWithdrawals,
        TransactionChargeback,
        TransactionTransference,
        UiCard,
        UiButton,
        TransferInformation,
        TransactionDetails
    },

    data() {
        return {
            files: [],
            data_files: [],
            notes: [],
            is_uploading: false,
            transaction: null,
            transaction_id: this.$route.query.id,
            transaction_type: null,
        };
    },

    mounted() {
        this.getTransactions();
    },

    methods: {
        async getTransactions() {
            try {
                const { data } = await this.$axios.get(`/_/transactions/${this.transaction_id}`);
                this.transaction = data;
                this.data_files = data?.files;
                this.notes = data.notes;
                this.transaction_type = this.getTransactionType(data);
            } catch (error) {
                console.error(error);
            }
        },
        async uploadFiles() {
            try {
                this.is_uploading = true;
                const payload = {
                    files: this.files,
                };
                const { data } = await this.$axios.post(`/_/transactions/${this.transaction_id}/files`, payload);
                this.data_files = [...this.data_files, ...data];
                this.files = [];
                this.$toast.success('Files uploaded successfully!');
            } catch (error) {
                console.error(error);
            } finally {
                this.is_uploading = false;
            }
        },

        getTransactionType(transaction) {
            if (transaction.withdrawal && Object.keys(transaction.withdrawal).length) return 'withdrawal';
            if (transaction.payout && Object.keys(transaction.payout).length) return 'payout';
            if (transaction.transfer && Object.keys(transaction.transfer).length) return 'transfer';
            if (transaction.order && Object.keys(transaction.order).length) return 'order';
            return null;
        },
    },
};
</script>

<style lang="scss">
.transaction-info {
    &__number {
        font-size: 0.95rem;
    }
}
</style>
