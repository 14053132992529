<template>
    <div :class="wrapperClass">
        <p class="mb-5px">{{ label }}</p>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-xl-4" :for="id">
                <div :class="{ empty: !files.length, 'd-none': files.length && !multiple }">
                    <button :disabled="is_uploading" class="btn btn-sm btn-outline-primary w-100 mt-3" @click="$refs.input.click()">
                        <i class="fas fa-plus me-1"></i>Add files
                    </button>
                    <input
                        v-bind="{ ...$attrs }"
                        :id="id"
                        class="d-none"
                        ref="input"
                        type="file"
                        :accept="accept ? accept.join(', ') : null"
                        :multiple="multiple"
                        @change="change"
                    />
                </div>
            </div>

            <slot></slot>
        </div>
        <div class="mt-3" :class="[{ 'is-invalid': state === false, 'is-valid': state === true, 'ds-wrapper': files.length }]">
            <ul v-if="files.length" class="list-files">
                <li v-for="(i, index) in files" :key="index" class="d-flex">
                    <img class="file-img" :src="previewFile(i)" :data-img-file="i.name" />
                    <div class="fs-14px">
                        <span class="break-all">{{ i.name }}</span> <br />
                        <small class="text-muted">{{ getMB(i.size) }}MB</small>
                    </div>
                    <button type="button" class="ms-auto" @click="deleteFile(index)">
                        <i class="fas fa-times-circle fa-lg text-danger"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        is_uploading: { type: Boolean, default: false },
        modelValue: { type: Array, default: () => [] },
        multiple: { type: Boolean, default: true },
        accept: { type: Array, default: null },
        state: { type: Boolean, default: null },
        wrapperClass: { type: String, default: 'mb-20px' },
        label: { type: String, default: null },
        base64: { type: Boolean, default: false },
    },

    data() {
        return {
            files: [],
            // id: `ds-${this.makeID()}`,
            id: 'ds-1',
        };
    },

    methods: {
        change(event) {
            this.files.push(...event.target.files);
            this.files = this.files.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
            this.$refs.input.value = '';
            this.emitModel();
        },

        deleteFile(index) {
            this.files.splice(index, 1);
            this.$refs.input.value = '';
            this.emitModel();
        },

        toBase64(files) {
            return Promise.all(
                files.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                    });
                })
            );
        },

        async emitModel() {
            if (this.base64) {
                const files = await this.toBase64([...this.files]);
                this.$emit('update:modelValue', files);
                return;
            }
            this.$emit('update:modelValue', this.files);
        },

        getMB(bytes) {
            return Math.floor(bytes * 0.000001 * 100) / 100;
        },

        previewFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener(
                    'load',
                    function () {
                        const el = document.querySelector(`img[data-img-file="${file.name}"]`);
                        if (el) {
                            el.src = /image/.test(file.type)
                                ? reader.result
                                : 'https://i.pinimg.com/564x/7f/d2/e4/7fd2e46b2da9819e667fb75caf475cf7.jpg';
                        }
                        resolve(reader.result);
                    },
                    false
                );
                if (file) {
                    reader.readAsDataURL(file);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.ds-wrapper {
    border: 2px dashed #babfc3;
    border-radius: 7px;
    max-height: 20rem;
    overflow-y: auto;
    padding: 3px;
    .file-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        display: inline-block;
        margin-right: 10px;
        border-radius: 7px;
    }
    .break-all {
        word-break: break-all;
    }
    &.is-invalid {
        border: 2px dashed $red;
        color: $red;
        background: mix($red, white, 5%);
    }
    &.is-valid {
        border: 2px dashed $green;
        background: mix($green, white, 5%);
    }
    position: relative;
    .text-placeholder {
        padding: 10px 20px;
        padding-top: 0;
    }
    .text-placeholder.empty {
        padding: 30px 20px;
    }
    .list-files {
        background: white;
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 99;
        button {
            background: none;
            border: none;
        }
        li {
            border: 1px solid #babfc3;
            padding: 5px 10px;
            border-bottom: 0;
            &:first-child {
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
            }
            &:last-child {
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
                border-bottom: 1px solid #babfc3;
            }
        }
    }
    label {
        display: block;
        margin: 0;
        cursor: pointer;
        position: relative;
    }
    input {
        display: none;
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // left: 0;
        // top: 0;
    }
    &:hover {
        background: #fafafa;
    }
}
</style>

<style lang="scss">
.ds-wrapper {
    .list-files {
        button {
            padding: 2px;
            outline: none;
            svg {
                fill: $red;
            }
        }
    }
}
</style>
