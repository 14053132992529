<template>
    <div class="withdrawals">
        <ui-card title="Transaction">
            <ui-list>
                <ui-list-item title="Status:">
                    <template #text>
                        <ui-status :status="transaction.status"></ui-status>
                    </template>
                </ui-list-item>
                <ui-list-item title="Amount:">
                    <template #text>
                        <ui-transaction-amount :amount="transaction.amount" :currency="transaction.currency" :type="transaction.type" />
                    </template>
                </ui-list-item>
                <ui-list-item title="Gateway:">
                    <template #text>
                        <span class="badge bg-success rounded-pill px-2 font-normal text-uppercase d-inline-block">
                            {{ transaction.gateway }}
                        </span>
                    </template>
                </ui-list-item>
                <ui-list-item v-if="transaction.object_id" title="Payment Identifier:" :text="transaction.object_id" />
            </ui-list>
        </ui-card>
        <ui-card v-if="Object.entries(transaction.withdrawal).length" title="Bank Account Information">
            <ui-list>
                <ui-list-item v-if="transaction.withdrawal.bank" title="Bank:" :text="transaction.withdrawal.bank" />
                <ui-list-item v-if="transaction.withdrawal.name" title="Name:" :text="transaction.withdrawal.name" />
                <ui-list-item v-if="transaction.withdrawal.method" title="Method:" :text="transaction.withdrawal.method" />
                <ui-list-item v-if="transaction.withdrawal.email" title="Email:" :text="transaction.withdrawal.email" />
                <ui-list-item v-if="transaction.withdrawal.last" title="Number:" :text="transaction.withdrawal.last" />
            </ui-list>
        </ui-card>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import UiTransactionAmount from '@/components/ui/TransactionAmount.vue';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus,
        UiTransactionAmount,
    },
    props: {
        transaction: {
            type: Object,
            required: true,
        },
    },
};
</script>
