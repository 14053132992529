<template>
    <div class="transaction-details">
        <ui-card>
            <div class="transaction-details__paid-at ms-auto">
                Paid at {{ getPaidAtDate(paid_at) }}
            </div>
            <div class="transaction-details__amount-container">
                <p class="transaction-details__amount-title">
                    {{ type }}
                </p>
                <span class="transaction-details__amount-total">{{ _currency(total, currency) }}</span>
                <hr />
                <div class="d-flex mt-3 flex-wrap align-items-center">
                    <ui-status class="me-auto" :status="status"></ui-status>
                    <p class="mb-0">{{ activity }} reference: <br /> #{{ reference }}</p>
                </div>
            </div>
            <p class="transaction-details__title">{{ activity }} details</p>
            <ul class="transaction-details__list-group list-group">
                <li :key="reference" class="transaction-details__list-group-item list-group-item">
                    <div class="transaction-details__list-group-icon">
                        <span class="fas fa-box"></span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="transaction-details__list-group-item-title text-capitalize">{{ concept }}</span>
                    </div>
                    <div class="ms-auto">
                        <span class="transaction-details__list-group-item-total">{{ _currency(total, currency) }}</span>
                    </div>
                </li>
            </ul>
            <p class="transaction-details__title">Total</p>
            <ul class="transaction-details__list-group transaction-details__list-group--no-internal-border list-group">
                <li class="transaction-details__list-group-item list-group-item">
                    <span class="transaction-details__list-group-item-title">Subtotal</span>
                    <span class="transaction-details__list-group-item-points"></span>
                    <span class="transaction-details__list-group-item-amount">{{ _currency(total, currency) }}</span>
                </li>
                <li class="transaction-details__list-group-item list-group-item">
                    <span class="transaction-details__list-group-item-title">Transaction commission</span>
                    <span class="transaction-details__list-group-item-points"></span>
                    <span class="transaction-details__list-group-item-amount transaction-details__list-group-item-amount--danger">{{ _currency(fee, currency) }}</span>
                </li>
                <li class="transaction-details__list-group-item list-group-item">
                    <span class="transaction-details__list-group-item-title">Total</span>
                    <span class="transaction-details__list-group-item-points"></span>
                    <span class="transaction-details__list-group-item-amount transaction-details__list-group-item-amount--success">{{ _currency(amount, currency) }}</span>
                </li>
            </ul>
        </ui-card>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: {
        UiCard,
        UiStatus,
    },
    props: {
        paid_at: {
            type: String,
            default: null,
        },
        amount: {
            type: Number,
            default: null,
        },
        currency: {
            type: Object,
            default: null,
        },
        fee: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        activity: {
            type: String,
            default: null,
        },
        reference: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            total: null
        };
    },
    created() {
        try {
            this.total = this.amount + this.fee;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        getPaidAtDate(date) {
            if (!isNaN(date)) {
                return this.$dayjs(date * 1000).format('LLLL');
            }
            return this.$dayjs(date).format('LLLL');
        },
    },
};
</script>

<style lang="scss" scoped>
.transaction-details {
    &__title {
        font-weight: 500;
        margin-bottom: 0;
    }

    &__paid-at {
        color: $ecart-secondary-500;
    }

    &__amount-container {
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        padding: 10px;
        text-align: center;

        hr {
            background-color: $ecart-secondary-100;
            margin-bottom: 10px;
            margin-top: 10px;
            opacity: 1;
        }
    }

    &__amount-title {
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__amount-total {
        color: $general-success;
        font-size: 1.71rem;
        font-weight: 600;
        word-break: break-all;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    &__list-group {
        border-radius: 8px;

        &--no-internal-border {
            li {
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
    }

    &__list-group-item {
        align-items: center;
        background-color: transparent;
        border-color: $ecart-secondary-100;
        display: flex;
        gap: 16px;
    }

    &__list-group-icon {
        align-items: center;
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        color: $ecart-secondary-300;
        display: flex;
        font-size: 25px;
        height: 50px;
        justify-content: center;
        min-width: 50px;
        width: 50px;
    }

    &__list-group-item-title {
        font-weight: 500;
    }

    &__list-group-item-total {
        font-weight: 500;
    }

    &__list-group-item-amount {
        font-weight: 500;
        color: $ecart-secondary-500;

        &--danger {
            color: $general-error;
            font-size: 1.2rem;
            font-weight: 600;
        }
        
        &--success {
            color: $general-success;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    &__list-group-item-points {
        flex: 1;
        border-bottom: 1px dashed $gray-300;
    }
}


.items {
    img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: $border-radius;
    }
}
.main-total {
    font-size: 26px;
    word-break: break-all;
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
}
.totals-details {
    margin-left: auto;
    min-width: 100%;
    @include media-breakpoint-up(md) {
        min-width: 80%;
    }
}
</style>
